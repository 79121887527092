<template>
    <div>
        <table>
            <tr v-for="(row, rowIndex) in reversedLayout"
                :key="rowIndex"
            >
                <td v-for="(symbol, reelIndex) in row"
                    :key="reelIndex"
                >
                    <div :style='{ backgroundImage: `url( ${imagePath(symbol)} )` }'
                         class="layout__symbol"

                         :class="{locked: isLockedSymbol(rowIndex, reelIndex )} "
                    />
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        layout: {
            type: Array,
            required: true,
        },
        symbolsPath: {
            type: String,
            required: true
        },
        lockLayout: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            symbol: {
                size: 80
            }
        }
    },
    computed: {
        rows() {
            return this.reversedLayout.length;
        },
        reels() {
            return this.reversedLayout[0].length;
        },
        reversedLayout() {
            return this.layout[0].map((col, i) => this.layout.map(row => row[i]))
        }
    },
    methods: {
        imagePath(symbol) {
            try {
                return require(`@/assets/games/slots/${this.symbolsPath}/${symbol}.png`);
            } catch (e) {
                return require(`@/assets/games/slots/default/${symbol}.png`);
            }
        },
        isLockedSymbol(rowIndex, reelIndex) {
            return this.lockLayout[reelIndex * this.reversedLayout[reelIndex].length + rowIndex];
        }
    },
    mounted() {
        this.$store.dispatch('workspace/setHeight', this.rows * this.symbol.size);
        this.$store.dispatch('workspace/setWidth', this.reels * this.symbol.size);
    }
}
</script>

<style scoped>
table {
    border-collapse: collapse;
    margin: auto;
}

td {
    border: 1px solid #dee2e6 !important;
}

.layout__symbol {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 80px;
    height: 80px;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.locked {
    background-color: #fc7777;
    background-blend-mode: normal;
}
</style>
