<template>
    <section>
        <modal-header :items="headers"/>
    </section>
</template>

<script>
import {fromCents} from "@/helpers/balanceConverter";
import ModalHeader from "../../../../../ModalHeader.vue";

export default {
    components: {ModalHeader},
    props: {
        bet: {
            type: Number,
            required: true
        },
        stake: {
            type: Number,
            required: true
        },
    },
    computed: {
        headers() {
            return {
                Bet: this.fromCents(this.bet),
                Stake: this.fromCents(this.stake),
            }
        }
    },
    methods: {
        fromCents,
    }
}
</script>
